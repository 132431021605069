/* eslint-disable react/prop-types */
import React from 'react'
import { Button, Result } from 'antd'

const Success = ({ onClose }) => (
  <Result
    status="success"
    title="Votre demande a bien été envoyée"
    subTitle="Votre demande sera traitée dans les meilleurs délais et une réponse vous sera alors transmise."
    extra={[
      <Button key="buy" onClick={onClose}>OK</Button>
    ]}
  />
)

export default Success
